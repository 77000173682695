/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Messages } from '@/store/messages/types';

const namespace: string = 'messages';

export default class MessagesReplyPage extends Vue {
	@State('messages') messages: Messages | undefined;
	@Action('fetchTodayMessages', {namespace}) fetchTodayMessages: any;
	@Action('storeMessages', {namespace}) storeMessages: any;
	@Action('fetchMessageForReply', {namespace}) fetchMessageForReply: any;
	@Getter('getTodayMessages', {namespace}) getTodayMessages: any;
	@Getter('getHistoryMessages', {namespace}) getHistoryMessages: any;
	@Getter('getMessageForReply', {namespace}) getMessageForReply: any;
	@Getter('getLastMessage', {namespace}) getLastMessage: any;

	myMessage: string = '';
	index: number = 0;
	toSwitch: boolean = false;

	toBack() {
		this.$router.push({ name: 'messages' }).then(r => r);
	}
	toMain() {
		this.$router.push({ name: '' }).then(r => r);
	}
	onSubmit() {
		this.index = this.getTodayMessages.length - 1;
		this.storeMessages({
			id: this.index++,
			time: Date.now(),
			message: this.myMessage
		})
		this.myMessage = '';
	}
	mounted() {
		this.fetchTodayMessages();
		this.fetchMessageForReply();
	}
}
